module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-NHPYS6EBC5","controlCookieName":"gatsby-gdpr-google-analytics","autoStart":false,"anonymize":true,"cookieFlags":"secure;samesite=none"},"googleAds":{"trackingId":"AW-16701235030","controlCookieName":"gatsby-gdpr-google-ads-conversion-tracking","anonymize":true,"cookieFlags":"secure;samesite=none"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"collective:mind | DEV","short_name":"collective:mind","start_url":"/","background_color":"#181818","theme_color":"#181818","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33f7d7076864faa2ae244046323ed0fc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true},"disableAutoprefixing":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"blog":"/builds/websites/collectivemind.dev/src/layouts/blog/defaultBlogLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1120,"linkImagesToOriginal":false,"backgroundColor":"none","disableBgImage":true,"quality":100,"showCaptions":false,"markdownCaptions":false}},"gatsby-remark-gifs",{"resolve":"gatsby-remark-image-attributes","options":{"dataAttributes":true}},{"resolve":"gatsby-remark-highlight-code","options":{"theme":"base16-light"}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank"}},"gatsby-remark-widows"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/websites/collectivemind.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://collectivemind.dev"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
